import React from "react";

import Layout from "../components/layout";
import Title from "../components/title";
import SEO from "../components/seo";
import LatestPosts from "../components/latestPosts";

function CakesPage() {
  return (
    <Layout>
      <SEO
        pageKeywords={[
            `menu`,
            `nuestra selección`
        ]}
        title="Dulces"
        lang="es"
      />
      <section className="mx-6 mb-16 md:mb-64">
        <Title titleName="Novedades" twSize="text-4xl" />
        <LatestPosts limit={24} />
      </section>
    </Layout>
  );
}

export default CakesPage;
